// @flow
import OriginalGravatar from "react-gravatar";
import styled from "styled-components";

export const Gravatar = styled(OriginalGravatar)`
  border-radius: 50%;
  flex: 0 0 auto;
  height: 50px;
  margin: 10px 25px 0 5px;
  width: 50px;
`;

export const SmallAgentGravatar = styled(OriginalGravatar)`
  border-radius: 50%;
  flex: 0 0 auto;
  height: 30px;
  margin: 0 10px 0 0;
  width: 30px;
`;
