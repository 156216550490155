// @flow
import { useEffect, useRef, useState } from "react";
import { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

const iconStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  margin-right: 10px;
`;

const emailBodyStyle = css`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

const emailBodyContainer = css`
  border: none;
  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
`;

const moreButtonStyle = css`
  background: ${({ theme }) => theme.palette.sand20};
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-radius: 5px;
  color: ${({ theme }) => theme.palette.hague70};
  cursor: pointer;
  margin-left: 8px;
`;

// This gets the height of the body in the iFrame (ie the email) and sets the iframe
// height to match (with a bit extra). The timeout is needed to ensure the iframe
// contentDocument, and therefore the body, is not null.
const setHeight = (iFrame: ?HTMLIFrameElement) => {
  const body =
    iFrame?.contentDocument?.body || iFrame?.contentWindow?.document?.body;
  if (body && iFrame) {
    // eslint-disable-next-line no-param-reassign
    iFrame.style.height = `${body.getBoundingClientRect().height + 35}px`;
  }
};

const truncateThread = (body) => {
  const textBeforeNextEmail = "&gt; wrote:<br>";
  const index = body.indexOf(textBeforeNextEmail);
  return index === -1
    ? body
    : body.slice(0, index + textBeforeNextEmail.length);
};

type Props = {
  body: ?string,
  expanded: boolean,
};

export const EmailBody = ({ body, expanded }: Props) => {
  const iFrameRef = useRef(null);
  const [bodyToDisplay, setBodyToDisplay] = useState("");

  // set the height of the email. The timeout is needed to ensure the
  // setHeight function runs after the expanded panel is collapsed for
  // an email that is already opened, as this appears to take time.
  useEffect(() => {
    if (iFrameRef.current) {
      setTimeout(
        () => {
          setHeight(iFrameRef.current);
        },
        expanded ? 0 : 400,
      );
    }
  }, [expanded, iFrameRef.current]);

  // initially truncates the thread to be just the latest message
  // so that the entire thread is not shown within each email.
  useEffect(() => {
    if (body) {
      setBodyToDisplay(truncateThread(body));
    }
  }, [body]);

  return bodyToDisplay ? (
    <div css={emailBodyStyle}>
      <iframe
        ref={iFrameRef}
        srcDoc={`<base target="_blank">${bodyToDisplay}</body>`}
        title="email content"
        css={emailBodyContainer}
        sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        csp="script-src 'none'"
        onLoad={() => setHeight(iFrameRef.current)}
      />
      {bodyToDisplay !== body && (
        <button css={moreButtonStyle} onClick={() => setBodyToDisplay(body)}>
          . . .
        </button>
      )}
    </div>
  ) : (
    <div css="padding: 10px 0 0 10px;">
      <FontAwesomeIcon icon={faCircleNotch} spin css={iconStyle} />
    </div>
  );
};
