// @flow

type Props = {
  className?: string,
};

export const SnoozeIcon = ({ className }: Props) => (
  <svg className={className} viewBox="0 0 17 18" fill="none">
    <path
      d="M8.37836 8.41871V11H11.8484C12.1076 12.4499 12.7118 13.0953 13.1269 13.5386C13.1716 13.5864 13.2142 13.6319 13.2539 13.6758C13.418 13.8398 13.5 14.0586 13.5 14.25C13.4726 14.7148 13.1445 15.125 12.5976 15.125H2.12499C1.57812 15.125 1.24999 14.7148 1.24999 14.25C1.22265 14.0586 1.30468 13.8398 1.46874 13.6758C1.50844 13.6319 1.55102 13.5864 1.59579 13.5386C2.13699 12.9605 2.99999 12.0387 2.99999 9.4375C2.99999 7.33203 4.47656 5.63672 6.49999 5.19922V4.625C6.49999 4.16016 6.88281 3.75 7.37499 3.75C7.83984 3.75 8.22265 4.16016 8.22265 4.625V5.19922C8.29391 5.21463 8.36449 5.23159 8.43436 5.25008V5.4H8.90086C9.3985 5.5893 9.85052 5.85948 10.2398 6.19492L8.37836 8.41871Z"
      fill="currentColor"
    />
    <path
      d="M10.9221 6.93774L9.37836 8.782V10H11.737C11.7276 9.82174 11.7226 9.63443 11.7226 9.4375C11.7226 9.14996 11.6951 8.87008 11.6424 8.6H11.3804L11.5866 8.35342C11.4533 7.83577 11.2258 7.35844 10.9221 6.93774Z"
      fill="currentColor"
    />
    <path
      d="M9.09765 16C9.09765 16.9844 8.33203 17.75 7.37499 17.75C6.39062 17.75 5.62499 16.9844 5.62499 16H9.09765Z"
      fill="currentColor"
    />
    <path
      d="M9.37836 10H15.1464V8.6H11.3804L15.0344 4.232V3H9.43436V4.4H13.0464L9.37836 8.782V10Z"
      fill="currentColor"
    />
  </svg>
);
