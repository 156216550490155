// @flow

type Props = {
  className?: string,
};

export const ClockRotateLeft = ({ className }: Props) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7852 2.96484C13.1159 4.27734 13.7812 5.8724 13.7812 7.75C13.7812 9.60938 13.1159 11.2044 11.7852 12.5352C10.4727 13.8659 8.8776 14.5312 7 14.5312C5.39583 14.5312 3.97396 14.0299 2.73438 13.0273C2.58854 12.8997 2.50651 12.7448 2.48828 12.5625C2.48828 12.362 2.5612 12.1888 2.70703 12.043L3.00781 11.7422C3.11719 11.6328 3.25391 11.5781 3.41797 11.5781C3.60026 11.5599 3.75521 11.5964 3.88281 11.6875C4.79427 12.4167 5.83333 12.7812 7 12.7812C8.38542 12.7812 9.57031 12.2891 10.5547 11.3047C11.5391 10.3203 12.0312 9.13542 12.0312 7.75C12.0312 6.36458 11.5391 5.17969 10.5547 4.19531C9.57031 3.21094 8.38542 2.71875 7 2.71875C5.66927 2.71875 4.52083 3.17448 3.55469 4.08594L4.94922 5.48047C5.07682 5.60807 5.10417 5.76302 5.03125 5.94531C4.95833 6.1276 4.82161 6.21875 4.62109 6.21875H0.65625C0.528646 6.21875 0.419271 6.18229 0.328125 6.10938C0.255208 6.01823 0.21875 5.90885 0.21875 5.78125V1.81641C0.21875 1.61589 0.309896 1.47917 0.492188 1.40625C0.674479 1.33333 0.829427 1.36068 0.957031 1.48828L2.32422 2.85547C3.63672 1.59766 5.19531 0.96875 7 0.96875C8.8776 0.96875 10.4727 1.63411 11.7852 2.96484ZM8.83203 9.88281C8.72266 10.0286 8.57682 10.1198 8.39453 10.1562C8.21224 10.1745 8.04818 10.1289 7.90234 10.0195L6.125 8.625V4.90625C6.125 4.72396 6.1888 4.56901 6.31641 4.44141C6.44401 4.3138 6.59896 4.25 6.78125 4.25H7.21875C7.40104 4.25 7.55599 4.3138 7.68359 4.44141C7.8112 4.56901 7.875 4.72396 7.875 4.90625V7.75L8.99609 8.625C9.14193 8.73438 9.22396 8.88021 9.24219 9.0625C9.26042 9.24479 9.21484 9.40885 9.10547 9.55469L8.83203 9.88281Z"
      fill="white"
    />
  </svg>
);
