// @flow
import moment from "moment";
import styled, { css } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hybridDatetimeFormat } from "@nested/utils/src/formatDate/formatDate";
import { ClockRotateLeft } from "@nested/ui/icons";
import { SnoozeIcon } from "../../icons/SnoozeIcon";
import { TeamMember } from "../TeamMember";

const Wrapper = styled.div`
  display: flex;
  font-size: 14px;
`;

export const descriptionStyle = css`
  word-wrap: break-word;
`;

const gravatarStyle = css`
  display: flex;
  margin-right: 5px;

  img {
    height: 20px;
    width: 20px;
  }
`;

const Agent = styled.div`
  align-items: center;
  align-self: ${({ $withGravatar }) => ($withGravatar ? "flex-start" : "auto")};
  display: flex;
  flex-shrink: 0;
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  align-self: flex-start;
`;

export const detailStyle = css`
  padding: 10px;
  white-space: pre-line;
  word-wrap: break-word;
  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
`;

const Card = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.hague20};
  border-radius: 5px;
  margin-bottom: 11px;
  min-height: 39px;
  overflow: hidden;
  width: ${({ $hideIcon }) => ($hideIcon ? "100%" : "calc(100% - 36px)")};
  background-color: ${({ theme, $bodyColor }) => {
    switch ($bodyColor) {
      case "sand":
        return theme.palette.sand40;
      case "yellow":
        return theme.palette.yellow10;
      default:
        return "white";
    }
  }};
`;

const IconWrapper = styled.div`
  background: ${({ theme, $color }) => {
    if ($color === "green") {
      return theme.palette.green100;
    }
    if ($color === "pink") {
      return theme.palette.pink150;
    }
    if ($color === "terracotta") {
      return theme.palette.terracotta100;
    }
    if ($color === "blue") {
      return theme.palette.blue150;
    }
    if ($color === "blue100") {
      return theme.palette.blue100;
    }
    if ($color === "lightblue") {
      return theme.palette.blue70;
    }
    if ($color === "yellow") {
      return theme.palette.yellow100;
    }
    if ($color === "grey") {
      return "grey";
    }
    return theme.palette.hague70;
  }};
  border-radius: 50%;
  color: white;
  border-top: 3px solid white;
  border-bottom: 3px solid white;
  height: 32px;
  line-height: 27px;
  margin: 4px 10px 0 0;
  min-width: 26px;
  text-align: center;
  z-index: 1;
  box-sizing: border-box;
`;

const Dot = styled.div`
  margin: 0 5px;
`;

export const UserAndDate = ({
  className,
  userEmail,
  userFirstName,
  timestamp,
}: {
  className?: string,
  userEmail?: ?string,
  userFirstName?: ?string,
  timestamp?: ?string,
}) => {
  // Don't render an empty div if we have no content
  if (!(Boolean(userEmail) || Boolean(userFirstName) || Boolean(timestamp)))
    return null;

  return (
    <Agent $withGravatar={!!userEmail} className={className}>
      {userEmail && (
        <TeamMember
          css={gravatarStyle}
          email={userEmail}
          fullName={userEmail}
        />
      )}
      {userFirstName && (
        <>
          <div>{userFirstName}</div>
          {/* This is unicode for a bullet point */}
          {timestamp && <Dot>&#x2022;</Dot>}
        </>
      )}
      {timestamp && (
        <div title={moment(timestamp).format("LLLL")}>
          {hybridDatetimeFormat(timestamp)}
        </div>
      )}
    </Agent>
  );
};

const UserAndDateInContent = styled(UserAndDate)`
  padding: 0 10px 10px 10px;
`;

const Summary = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  gap: 10px;
  line-height: 20px;

  div {
    word-wrap: break-word;
  }

  background-color: ${({ theme, $headingColor }) => {
    switch ($headingColor) {
      case "sand":
        return theme.palette.sand40;
      case "yellow":
        return theme.palette.yellow10;
      default:
        return "white";
    }
  }};
`;

const PrimaryHeading = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;

const Heading = styled.div`
  flex-grow: 1;
`;

const Icon = ({ icon }) => {
  if (icon === "snooze")
    return <SnoozeIcon css="width: 17px; margin-top: 3px;" />;
  if (icon === "clock") return <ClockRotateLeft css="margin-top: 5px;" />;
  return <FontAwesomeIcon icon={icon} />;
};

type Props = {
  bodyColor?: string,
  children?: React$Node,
  className?: string,
  color?:
    | "green"
    | "pink"
    | "terracotta"
    | "blue"
    | "blue100"
    | "lightblue"
    | "yellow"
    | "grey",
  headingColor?: string,
  icon: "snooze" | "clock" | (({ fill?: string }) => Element<"svg">),
  primaryHeading?: string,
  noteId?: string,
  secondaryHeading?: string,
  testId?: string,
  timestamp?: ?string,
  userEmail?: ?string,
  userFirstName?: ?string,
  userAvatarPosition?: "heading" | "content",
  hideIcon?: boolean,
  pinButton?: React$Node,
};

export const TimelineItem = ({
  bodyColor,
  children,
  className,
  color,
  headingColor,
  icon,
  primaryHeading,
  secondaryHeading,
  testId,
  pinButton = null,
  timestamp,
  userEmail,
  userFirstName,
  userAvatarPosition = "header",
  hideIcon = false,
}: Props) => (
  <Wrapper data-test={testId}>
    {!hideIcon && (
      <IconWrapper $color={color}>
        <Icon icon={icon} />
      </IconWrapper>
    )}
    <Card $hideIcon={hideIcon} className={className} $bodyColor={bodyColor}>
      {primaryHeading && (
        <Summary $headingColor={headingColor}>
          <Heading>
            <PrimaryHeading>{primaryHeading}</PrimaryHeading>
            <span>{secondaryHeading}</span>
          </Heading>
          {userAvatarPosition === "header" ? (
            <UserAndDate
              userEmail={userEmail}
              userFirstName={userFirstName}
              timestamp={timestamp}
            />
          ) : (
            <UserAndDate timestamp={timestamp} />
          )}
          {pinButton}
        </Summary>
      )}
      {children}
      {userAvatarPosition === "content" && (
        <UserAndDateInContent
          userEmail={userEmail}
          userFirstName={userFirstName}
        />
      )}
    </Card>
  </Wrapper>
);
