// @flow
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import { detailStyle, TimelineItem } from "./TimelineItem";

type Props = {
  id: string,
  nestedUser: { email: string, firstName?: string },
  recipient: string,
  smsBody?: ?string,
  timestamp: string,
};

export const SMS = ({
  id,
  nestedUser,
  recipient,
  smsBody,
  timestamp,
}: Props) => (
  <TimelineItem
    color="terracotta"
    icon={faCommentDots}
    primaryHeading="SMS"
    secondaryHeading={recipient}
    testId={id}
    timestamp={timestamp}
    userEmail={nestedUser.email}
    userFirstName={nestedUser.firstName}
  >
    {smsBody && (
      <div css={detailStyle} data-test="timeline-sms-body-text">
        {smsBody}
      </div>
    )}
  </TimelineItem>
);
