// @flow
type Props = {
  className?: string,
};

export const NestedLogo = ({ className }: Props) => {
  return (
    <svg
      className={className}
      viewBox="0 0 30 34"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Artboard-Copy-2"
          transform="translate(-30.000000, -40.000000)"
          fillRule="nonzero"
        >
          <g id="Group-9">
            <g id="Group" transform="translate(30.000000, 40.000000)">
              <path
                d="M28.6050062,10.131586 L16.0607894,0.37054695 C15.4265034,-0.12351565 14.5468841,-0.12351565 13.912598,0.37054695 L1.39210563,10.1313754 C0.514814365,10.8154041 0,11.8778629 0,13.004784 L0,32.2709847 C0,33.2258935 0.758301926,34 1.69371476,34 L28.3062852,34 C29.2416981,34 30,33.2258935 30,32.2709847 L30,13.0066794 C30,11.8786149 29.4839716,10.8152594 28.6050062,10.131586 L28.6050062,10.131586 Z"
                id="Path"
                fill="#FF8871"
              ></path>
              <path
                d="M25.7217714,28.8748701 C25.7217714,29.3089407 25.3770719,29.6608244 24.9518636,29.6608244 L20.9071655,29.6608244 C20.4819571,29.6608244 20.1372576,29.3089407 20.1372576,28.8748701 L20.1372576,18.6877899 C20.1372576,15.760468 17.7941136,13.392075 14.917893,13.4301934 C12.0874708,13.46768 9.83612983,15.8762973 9.83612983,18.7661326 L9.83612983,28.8748701 C9.83623942,29.0835006 9.75508776,29.2836153 9.61055626,29.4311196 C9.46602476,29.5786239 9.26997406,29.6612459 9.06560308,29.6612459 L5.02090496,29.6612459 C4.81671271,29.6612459 4.62088363,29.57844 4.4764979,29.4310449 C4.33211217,29.2836499 4.25099711,29.0837393 4.25099711,28.8752913 L4.25099711,18.792668 C4.25099711,12.7253196 9.03713382,7.73181383 14.9808142,7.72886393 C20.9123229,7.72570647 25.7217714,12.6334985 25.7217714,18.6877899 L25.7217714,28.8748701 Z"
                id="Path"
                fill="#FFFFFF"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
