// @flow
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import { TimelineItem, detailStyle } from "./TimelineItem";

type CallEvent = {
  children?: React$Node,
  id: string,
  markNoteAsSharedWithSeller?: boolean,
  nestedUser: {
    email: string,
    firstName?: string,
  },
  recipient: string,
  note?: ?{
    text: string,
  },
  timestamp: string,
};

export const Call = ({
  children,
  id,
  markNoteAsSharedWithSeller,
  nestedUser,
  note,
  recipient,
  timestamp,
}: CallEvent) => (
  <TimelineItem
    bodyColor={markNoteAsSharedWithSeller ? "yellow" : undefined}
    color="pink"
    expanded
    headingColor={markNoteAsSharedWithSeller ? "yellow" : undefined}
    icon={faPhone}
    markNoteAsSharedWithSeller={markNoteAsSharedWithSeller}
    primaryHeading="Call"
    secondaryHeading={recipient}
    testId={`timeline-call-${id}`}
    timestamp={timestamp}
    userEmail={nestedUser.email}
    userFirstName={nestedUser.firstName}
  >
    {/* If there are children given to this component, we defer to them. If
    not, we display note content if we have it. This lets us leave note CRUD
    logic in the Nest but have a consistent way of displaying them in the
    TimelineItem component */}
    {children || (note && <div css={detailStyle}>{note.text}</div>)}
  </TimelineItem>
);
