// @flow
import moment from "moment-timezone";

export const formatDateWithMoment = (date: string): string =>
  moment(date).format("DD/MM/YY");

// Because moment treats dates (without times) as having time 00:00
// its important we compare it with 'now' also having time 00:00 to
// avoid the time of day affecting the rounding
export const formatDateDifference = (date: string, now: * = moment()) => {
  if (moment(date).isSame(now, "day")) {
    return "Today";
  }
  return formatDatetimeDifference(date, now.clone().startOf("day"));
};

export const formatDatetimeDifference = (time: string, now: * = moment()) => {
  // If later today - '10:40am today'
  if (moment(time).isAfter(now) && moment(time).isSame(now, "day")) {
    return `${moment(time).format("h:mma")} today`;
  }
  // e.g. '2 days ago'
  return moment(time).from(now);
};

export const formatDateTime = (
  date: ?string,
  {
    appendTime,
    shortDate = false,
  }: {
    appendTime: boolean,
    shortDate?: boolean,
  },
) => {
  const dateStyle = shortDate ? "D" : "Do";

  if (appendTime) {
    return moment(date).calendar(null, {
      sameDay: "k:mm",
      nextDay: "",
      nextWeek: "",
      lastDay: "[yesterday] k:mm",
      lastWeek: "dddd k:mm",
      sameElse: `${dateStyle} MMM k:mm`,
    });
  }

  // if previous year -> Jan 2018
  if (moment(date).year() < moment().year()) {
    return moment(date).format(`${dateStyle} MMM 'YY`);
  }

  return moment(date).calendar(null, {
    sameDay: "k:mm",
    nextDay: "",
    nextWeek: "",
    lastDay: "[yesterday]",
    lastWeek: "dddd",
    sameElse: `${dateStyle} MMM`,
  });
};

export const formatShortDate = (date: ?string) =>
  // if previous year -> 2 Jan '18
  moment(date).year() < moment().year()
    ? moment(date).format("D MMM 'YY")
    : moment(date).format("D MMM");

// Returns '2 days ago' or '5 minutes ago' if date is in the last week, otherwise
// formats a date, e.g. "25 Mar"
export const hybridDatetimeFormat = (date: string) => {
  const oneWeekAgo = moment().subtract(1, "week");
  if (moment(date).isBefore(oneWeekAgo)) {
    return formatDateTime(date, { appendTime: false, shortDate: true });
  }
  return formatDatetimeDifference(date);
};

export const getDateDiff = (start: DateTime, until: DateTime) => {
  const a = moment(until).startOf("day");
  const b = moment(start).startOf("day");
  const diff = a.diff(b, "days");
  if (diff < 7) {
    return `${diff} ${diff === 1 ? "day" : "days"}`;
  }
  if (diff < 31) {
    const weekDiff = a.diff(b, "weeks");
    return `${weekDiff} ${weekDiff === 1 ? "week" : "weeks"}`;
  }
  const monthDiff = a.diff(b, "months");
  return `${monthDiff} ${monthDiff === 1 ? "month" : "months"}`;
};

export const prettyFormat = (date: string | Date) =>
  moment(date).format("dddd, MMM Do YYYY");
