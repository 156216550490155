// @flow
import { useState } from "react";
import styled from "styled-components";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { detailStyle, TimelineItem } from "./TimelineItem";
import { Email, type EmailItem } from "./Emails/Email";

const DISPLAYED_THREAD_LENGTH = 2;

const ShowMore = styled.div`
  ${detailStyle}
  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
  color: ${({ theme }) => theme.palette.hague70};
  cursor: pointer;
  font-size: 12px;
  padding: 5px 10px;
`;

type Props = {
  id: string,
  emails: EmailItem[],
  expanded?: boolean,
  fetchEmail: (string) => Promise<any>,
  subject: string,
};

export const EmailThread = ({
  id,
  emails,
  expanded,
  fetchEmail,
  subject,
}: Props) => {
  const [showAllEmails, setShowAllEmails] = useState(false);
  const emailsRemaining = emails.length - DISPLAYED_THREAD_LENGTH;
  const emailsToDisplay = showAllEmails
    ? emails
    : emails.slice(0, DISPLAYED_THREAD_LENGTH);

  return (
    <TimelineItem
      icon={faEnvelope}
      color="lightblue"
      testId={id}
      primaryHeading="Email"
      secondaryHeading={subject}
    >
      {emailsToDisplay.map((email) => (
        <Email
          email={email}
          key={email.id}
          expanded={expanded}
          fetchEmail={fetchEmail}
        />
      ))}
      {emailsRemaining > 0 && !showAllEmails && (
        <ShowMore
          onClick={() => setShowAllEmails(true)}
          role="button"
          tabIndex={0}
          onKeyPress={() => setShowAllEmails(true)}
        >
          {emailsRemaining} more...
        </ShowMore>
      )}
    </TimelineItem>
  );
};
