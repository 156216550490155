// @flow
import { SmallAgentGravatar } from "./Gravatars";

type TeamMemberProps = {
  className?: string,
  email: string,
  fullName: string,
};

export const TeamMember = ({ className, email, fullName }: TeamMemberProps) => (
  <div
    className={className}
    data-test={`team:team-member:${email}`}
    title={fullName}
  >
    <SmallAgentGravatar css="margin: 0;" email={email} />
  </div>
);
