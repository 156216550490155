// @flow
import jwtDecode from "jwt-decode";
import { useCookies } from "react-cookie";
import { getConfig } from "@nested/config";

const AUTH_COOKIE = "com.nested.tempjwt";

const { COOKIE_DOMAIN } = getConfig();

export const useTemporaryToken = () => {
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_COOKIE]);

  const saveTemporaryToken = (idToken: string) => {
    const parsedToken = jwtDecode(idToken);
    // $FlowFixMe flow thinks this is a graphql scalar type...
    const expires = new Date(parsedToken.exp * 1000);
    setCookie(AUTH_COOKIE, idToken, {
      domain: COOKIE_DOMAIN,
      expires,
      path: "/",
    });
  };

  const removeTemporaryToken = () => {
    removeCookie(AUTH_COOKIE, {
      domain: COOKIE_DOMAIN,
      path: "/",
    });
  };

  const temporaryToken = cookies[AUTH_COOKIE];

  return { temporaryToken, saveTemporaryToken, removeTemporaryToken };
};
