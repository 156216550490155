// @flow
import Confetti from "react-dom-confetti";
import { css } from "styled-components";

const confettiStyle = css`
  position: fixed;
  left: 50%;
`;

const defaultConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 25,
  elementCount: 70,
  dragFriction: 0.08,
  duration: 5000,
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "1000px",
  colors: ["#FF8770", "#FFD4DB", "#FFC22B", "#47A6E5", "#F2EBE3"],
};

type Props = {
  customConfig?: {|
    angle?: number,
    spread?: number,
    startVelocity?: number,
    elementCount?: number,
    dragFriction?: number,
    duration?: number,
    stagger?: number,
    width?: string,
    height?: string,
    perspective?: string,
    colors?: Array<string>,
  |},
  showConfetti: boolean,
};

const ConfettiComponent = ({ customConfig, showConfetti }: Props) => {
  const config = { ...defaultConfig, ...customConfig };
  return <Confetti active={showConfetti} config={config} css={confettiStyle} />;
};

export { ConfettiComponent as Confetti };
