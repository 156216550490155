// @flow
import { useState, useMemo } from "react";
import { css } from "styled-components";
import moment from "moment";
import { groupBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { hybridDatetimeFormat } from "@nested/utils/src/formatDate/formatDate";
import { detailStyle } from "../TimelineItem";
import { EmailBody } from "./EmailBody";

const gridContainer = css`
  ${detailStyle}
  width: 100%;
  border-bottom: none;
  border-left: none;
  border-right: none;
  display: grid;
  background-color: unset;
  grid-gap: 0 10px;
  grid-template-columns: 5px auto fit-content(40px);
  cursor: pointer;
  color: ${({ theme }) => theme.palette.hague70};
  text-align: left;

  border-top: 1px solid ${({ theme }) => theme.palette.hague20};
`;

const rotate = css`
  transform: rotate(90deg);
`;

const iconStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  margin-right: 10px;
  ${({ $viewEmail }) => $viewEmail && rotate};
`;

const ellipsisStyle = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const senderStyle = css`
  color: ${({ theme }) => theme.palette.hague100};
  text-align: left;
  font-size: 14px;
  ${ellipsisStyle}; ;
`;

const receiverStyle = css`
  ${senderStyle}
  grid-column: 2 / 4;
`;

const emailerText = css`
  width: 100%;
  color: ${({ theme }) => theme.palette.hague70};
  line-height: 18px;
`;

const snippetStyle = css`
  color: ${({ theme }) => theme.palette.hague100};
  margin-left: 5px;
`;

const dateStyle = css`
  color: ${({ theme }) => theme.palette.hague70};
  font-size: 12px;
  text-align: right;
  white-space: nowrap;
`;

const fromOrTo = css`
  font-weight: 500;
  display: inline-block;
  width: 38px;
`;

const capitalise = (str) => str[0].toUpperCase() + str.slice(1);

export type EmailItem = {
  id: string,
  senderEmail: string,
  senderName: string,
  snippet: string,
  sentAt: DateTime,
};

type Props = {
  email: EmailItem,
  fetchEmail: (string) => Promise<any>,
  expanded?: boolean,
};

export const Email = ({
  email: { id, senderName, senderEmail, snippet, sentAt },
  fetchEmail,
  expanded,
}: Props) => {
  const [fullEmail, setFullEmail] = useState(null);
  const [viewEmail, setViewEmail] = useState(false);
  const emailRecipients = fullEmail?.recipients || [];

  const recipients = useMemo(() => {
    return groupBy(emailRecipients, (r) => r.type);
  }, [emailRecipients]);

  const toggleExpansion = async () => {
    setViewEmail(!viewEmail);
    if (!viewEmail && !fullEmail) {
      setFullEmail(await fetchEmail(id));
    }
  };

  return (
    <>
      <button css={gridContainer} onClick={toggleExpansion}>
        <FontAwesomeIcon
          icon={faCaretRight}
          css={iconStyle}
          $viewEmail={viewEmail}
        />
        {viewEmail ? (
          <div css={senderStyle}>
            <span css={fromOrTo}>From:</span>{" "}
            <span css={emailerText} title={senderEmail}>
              {senderName} &lt;{senderEmail}&gt;
            </span>
          </div>
        ) : (
          <div css={ellipsisStyle}>
            <span title={senderEmail}>{senderName}</span>{" "}
            <span css={snippetStyle}>{snippet}</span>
          </div>
        )}
        <div css={dateStyle} title={moment(sentAt).format("LLLL")}>
          {hybridDatetimeFormat(sentAt)}
        </div>
        {viewEmail &&
          Object.keys(recipients).map((type, index) => (
            <div key={index} css={receiverStyle}>
              <span css={fromOrTo}>{capitalise(type)}: </span>
              {recipients[type].map(
                ({ id: recipientId, name, email: emailAddress }, i) => {
                  return (
                    <span
                      css={emailerText}
                      key={i}
                      data-test={`recipient-${type}-${recipientId}`}
                      title={emailAddress}
                    >
                      {name} &lt;{emailAddress}&gt;
                      {i < recipients[type].length - 1 ? ", " : ""}
                    </span>
                  );
                },
              )}
            </div>
          ))}
      </button>
      {viewEmail && (
        <EmailBody body={fullEmail?.body} expanded={Boolean(expanded)} />
      )}
    </>
  );
};
