// @flow
import { useEffect } from "react";
import { css } from "styled-components";
import queryString from "query-string";
import { media } from "@nested/brand";
import { Button } from "@nested/component-library";

const buttonStyle = css`
  margin-top: 30px;
  ${media.tablet`
    margin-top: 40px;
  `}
`;

type Props = {
  address?: string,
  email: string,
  name: string,
  phone: string,
  url?: string,
  onSuccess: () => void,
  onFailure: () => void,
  onSkip?: () => void,
};

const addQueryParams = (url, newParams) => {
  const parsed = queryString.parseUrl(url);
  return queryString.stringifyUrl({
    ...parsed,
    query: {
      ...parsed.query,
      ...newParams,
    },
  });
};

const formatPhone = (phone) =>
  phone.startsWith("+44") ? phone : `+44${phone || ""}`;

export const CalendlyWidget = ({
  address = "",
  email,
  name,
  phone,
  onSuccess,
  onFailure,
  onSkip = null,
  url,
}: Props) => {
  useEffect(() => {
    if (!window.Calendly || !url) {
      onFailure();
      return;
    }

    const onEventScheduled = (e) => {
      if (e.data?.event === "calendly.event_scheduled") {
        onSuccess();
      }
    };

    window.addEventListener("message", onEventScheduled);

    window.Calendly.initInlineWidget({
      url: addQueryParams(url, {
        // Hides heading and meeting info, as they should be displayed on the website in our own branding
        hide_event_type_details: 1,
        hide_landing_page_details: 1,
      }),
      parentElement: document.getElementById("book-a-call-widget"),
      prefill: {
        name,
        email,
        customAnswers: {
          a1: formatPhone(phone),
          a2: address,
        },
      },
    });

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      window.removeEventListener("message", onEventScheduled);
    };
  }, []);

  if (!url) {
    return null;
  }

  return (
    <>
      <div
        data-test="book-a-call-widget"
        id="book-a-call-widget"
        css="min-width:270px;height:460px;padding:1px"
      ></div>
      {onSkip && (
        <Button
          data-test="calendly:skip-button"
          css={buttonStyle}
          size="large"
          onClick={() => onSkip()}
        >
          Can't find a time?
        </Button>
      )}
    </>
  );
};
